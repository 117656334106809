import { loadStripe } from "@stripe/stripe-js";

import {
  handleAjaxFormSubmitProtection,
  sendBrowserAgnosticEvent,
} from "../core/utils";

export default async function initAddPaymentMethod(props) {
  const stripe = await loadStripe(props.stripePublishableKey);

  document.body.addEventListener("initAddPaymentMethod", (event) => {
    const paymentMethodForm = document.getElementById("manage-payment-method");
    if (!paymentMethodForm) {
      return;
    }

    document
      .querySelectorAll('input[name="payment_method"][type="radio"]')
      .forEach((radio) => {
        radio.addEventListener("change", () => {
          if (radio.checked) {
            document
              .querySelectorAll(".payment-method-select-listener")
              .forEach((listener) => {
                window.htmx.trigger(listener, "paymentMethodSelected");
              });
          }
        });

        if (radio.checked) {
          document
            .querySelectorAll(".payment-method-select-listener")
            .forEach((listener) => {
              window.htmx.trigger(listener, "paymentMethodSelected");
            });
        }
      });

    const elements = stripe.elements({
      appearance: {
        theme: "stripe",
        variables: {
          colorPrimary:
            getComputedStyle(document.body).getPropertyValue("--primary-brand-color") ||
            "#7f465c",
          fontFamily: "Lato",
        },
        labels: "floating",
      },
      clientSecret: event.detail.clientSecret,
      fonts: [{ cssSrc: "https://fonts.googleapis.com/css?family=Lato:300,400,700" }],
      loader: "always",
    });

    const style = {
      base: {
        fontFamily: "Lato",
        fontSize: "18px",
        "::placeholder": {
          color: "#8e8e8e",
        },
      },
      invalid: {
        color: "#c30c30",
      },
    };

    // Create instances of the cardNumber, cardExpiry and cardCvc Elements.
    const cardNumber = elements.create("cardNumber", { style });
    const cardExpiry = elements.create("cardExpiry", { style });
    const cardCvc = elements.create("cardCvc", { style });

    // Mount them.
    cardNumber.mount("#stripe-card-element");
    cardExpiry.mount("#stripe-exp-element");
    cardCvc.mount("#stripe-cvc-element");

    cardNumber.addEventListener("change", (evt) => {
      const displayError = document.getElementById("stripe-card-errors");
      if (evt.error) {
        displayError.textContent = evt.error.message;
        displayError.classList.remove("d-none");
      } else {
        displayError.textContent = "";
        displayError.classList.add("d-none");
      }
    });

    cardExpiry.addEventListener("change", (evt) => {
      const displayError = document.getElementById("stripe-exp-errors");
      if (evt.error) {
        displayError.textContent = evt.error.message;
        displayError.classList.remove("d-none");
      } else {
        displayError.textContent = "";
        displayError.classList.add("d-none");
      }
    });

    cardCvc.addEventListener("change", (evt) => {
      const displayError = document.getElementById("stripe-cvc-errors");
      if (evt.error) {
        displayError.textContent = evt.error.message;
        displayError.classList.remove("d-none");
      } else {
        displayError.textContent = "";
        displayError.classList.add("d-none");
      }
    });

    const cardholderNameInput = document.getElementById("cardholder-name");
    const zipCodeInput = document.getElementById("zip");
    const submitButton = document.getElementById("save-card");

    submitButton.addEventListener("click", async (clickEvent) => {
      clickEvent.preventDefault();
      clickEvent.stopPropagation();

      const reverseSubmitProtection = handleAjaxFormSubmitProtection(submitButton);

      const paymentMethodDetails = {
        billing_details: {
          address: {
            postal_code: zipCodeInput.value,
          },
          name: cardholderNameInput.value,
        },
        card: cardNumber,
      };

      const response = await stripe.confirmCardSetup(event.detail.clientSecret, {
        payment_method: paymentMethodDetails,
      });

      if (response.error) {
        if (response.error.message) {
          const displayError = document.getElementById("payment-method-errors");
          displayError.innerText = response.error.message;
          displayError.classList.remove("d-none");
        } else if (window.Rollbar) {
          window.Rollbar.error("Error confirming PaymentMethod setup", response.error);
        }

        reverseSubmitProtection();

        return;
      }

      paymentMethodForm.setAttribute(
        "hx-vals",
        JSON.stringify({ setup_intent: response.setupIntent.id }),
      );
      sendBrowserAgnosticEvent(paymentMethodForm, "paymentMethodAdded");
    });
  });
}
