import { loadStripe } from "@stripe/stripe-js";
import Collapse from "bootstrap/js/dist/collapse";

import { fadeIn, sendBrowserAgnosticEvent, scrollToElement } from "../core/utils";

const toggleSubmitButtonEnabled = (submitButton, enabled) => {
  if (submitButton) {
    submitButton.toggleAttribute("disabled", !enabled);
    submitButton.classList.toggle("disabled", !enabled);
  }
};

const clearError = () => {
  const errorMessageContainer = document.querySelector("#pc-form .payment-error");
  if (errorMessageContainer) {
    errorMessageContainer.classList.add("d-none");
  }
};

const showError = (errorMessage) => {
  const errorMessageContainer = document.querySelector("#pc-form .payment-error");
  if (errorMessageContainer) {
    errorMessageContainer.innerText = errorMessage;
    fadeIn(errorMessageContainer);
  }
};

export default async function initPayableCheckout(props) {
  const stripe = await loadStripe(props.stripePublishableKey);

  document.body.addEventListener("payableCheckoutStarted", () => {
    clearError();
  });

  document.body.addEventListener("payableCheckoutCanceled", (event) => {
    if (event && event.payload && event.payload.message) {
      showError(event.payload.message);
      toggleSubmitButtonEnabled(
        document.querySelector("#payable-checkout-submit"),
        false,
      );
    }
  });

  document.body.addEventListener("initPayableCheckout", (event) => {
    sendBrowserAgnosticEvent(document.body, "elep:disable");
    scrollToElement(document.body);

    const checkoutForm = document.getElementById("pc-form");

    if (checkoutForm) {
      const elements = stripe.elements({
        clientSecret: event.detail.clientSecret,
        fonts: [{ cssSrc: "https://fonts.googleapis.com/css?family=Lato:300,400,700" }],
        loader: "always",
      });

      const style = {
        base: {
          fontFamily: "Lato",
          fontSize: "18px",
          "::placeholder": {
            color: "#8e8e8e",
          },
        },
        invalid: {
          color: "#c30c30",
        },
      };

      const submitButton = document.querySelector("#payable-checkout-submit");

      const zipInput = document.getElementById("zip");
      const cardholderInput = document.getElementById("cardholder-name");

      const stripeElementContainerCollapse = Collapse.getOrCreateInstance(
        ".stripe-element-container",
        { toggle: false },
      );

      // Create instances of the cardNumber, cardExpiry and cardCvc Elements.
      const cardNumber = elements.create("cardNumber", { showIcon: true, style });
      const cardExpiry = elements.create("cardExpiry", { style });
      const cardCvc = elements.create("cardCvc", { style });

      cardNumber.on("ready", () => {
        submitButton.classList.remove("d-none");
        toggleSubmitButtonEnabled(submitButton, false);
      });

      cardNumber.mount("#pc-card-element");
      cardExpiry.mount("#pc-exp-element");
      cardCvc.mount("#pc-cvc-element");

      let cardNumberValid = false;
      let cardExpiryValid = false;
      let cardCvcValid = false;

      // This uses private/protected variables in the Stripe Elements implementation.
      // We have no guarantee that they will remain consistent, but there is indication
      // that Stripe *probably* won't change it:
      // https://github.com/stripe-archive/react-stripe-elements/issues/283#issuecomment-772659591
      const allInputsReady = () =>
        cardNumberValid &&
        cardExpiryValid &&
        cardCvcValid &&
        zipInput.value.length >= 3 &&
        cardholderInput.value;

      [cardNumber, cardExpiry, cardCvc].forEach((el) =>
        el.on("change", (changeEvent) => {
          switch (el) {
            case cardNumber:
              cardNumberValid = changeEvent.complete;
              break;
            case cardExpiry:
              cardExpiryValid = changeEvent.complete;
              break;
            case cardCvc:
              cardCvcValid = changeEvent.complete;
              break;
            default:
              break;
          }

          const isReady =
            allInputsReady() && changeEvent.complete && !changeEvent.error;
          toggleSubmitButtonEnabled(submitButton, isReady);

          if (changeEvent.error && changeEvent.error.message) {
            showError(changeEvent.error.message, submitButton);
          } else if (!changeEvent.error) {
            clearError(submitButton);
          }
        }),
      );

      zipInput.addEventListener("input", () => {
        toggleSubmitButtonEnabled(submitButton, allInputsReady());
      });

      cardholderInput.addEventListener("input", () => {
        toggleSubmitButtonEnabled(submitButton, allInputsReady());
      });

      const paymentMethodSelect = checkoutForm.querySelector(
        'select[name="saved-payment-method"]',
      );

      if (paymentMethodSelect) {
        paymentMethodSelect.addEventListener("change", () => {
          if (paymentMethodSelect.value) {
            stripeElementContainerCollapse.hide();
            toggleSubmitButtonEnabled(submitButton, true);
          } else {
            stripeElementContainerCollapse.show();
            toggleSubmitButtonEnabled(submitButton, false);
          }
        });
      }

      const savePaymentMethod = checkoutForm.querySelector("#save-payment-method");

      const completeCheckout = async (
        paymentMethodFromPaymentRequest = null,
        completeCallback = null,
      ) => {
        let response;

        if (event.detail.isSetupIntent) {
          response = await stripe.confirmCardSetup(event.detail.clientSecret, {
            payment_method: {
              card: cardNumber,
              billing_details: {
                name: cardholderInput.value,
                address: {
                  postal_code: zipInput.value,
                },
              },
            },
          });
        } else {
          let paymentData;
          if (paymentMethodFromPaymentRequest) {
            paymentData = { payment_method: paymentMethodFromPaymentRequest.id };
          } else {
            paymentData = {
              payment_method: {
                card: cardNumber,
                billing_details: {
                  name: cardholderInput.value,
                  address: {
                    postal_code: zipInput.value,
                  },
                },
              },
            };

            if (savePaymentMethod && savePaymentMethod.checked) {
              paymentData.setup_future_usage = "off_session";
            }
          }

          response = await stripe.confirmCardPayment(
            event.detail.clientSecret,
            paymentData,
          );
        }

        if (response.error) {
          if (completeCallback) {
            completeCallback("fail");
          }

          if (response.error.message) {
            showError(response.error.message);
            toggleSubmitButtonEnabled(submitButton, false);
          } else if (window.Rollbar) {
            window.Rollbar.error(
              `Error confirming checkout ${event.detail.isSetupIntent ? "setup" : "payment"}`,
              response.error,
            );
          }

          sendBrowserAgnosticEvent(
            checkoutForm,
            "payableCheckoutCanceled",
            response.error,
          );
          sendBrowserAgnosticEvent(checkoutForm, "cancel-submit-protect");

          return;
        }

        if (completeCallback) {
          completeCallback("success");
        }

        if (
          !event.detail.isSetupIntent &&
          response.paymentIntent.status === "requires_action"
        ) {
          const actionResponse = await stripe.confirmCardPayment(
            event.detail.clientSecret,
          );

          if (actionResponse.error) {
            if (actionResponse.error.message) {
              showError(actionResponse.error.message);
              toggleSubmitButtonEnabled(submitButton, false);
            } else if (window.Rollbar) {
              window.Rollbar.error(
                `Error confirming checkout ${event.detail.isSetupIntent ? "setup" : "payment"} (inner confirm)`,
                response.error,
              );
            }

            sendBrowserAgnosticEvent(
              checkoutForm,
              "payableCheckoutCanceled",
              actionResponse.error,
            );
            sendBrowserAgnosticEvent(checkoutForm, "cancel-submit-protect");

            return;
          }
        }

        sendBrowserAgnosticEvent(checkoutForm, "payableCheckoutComplete");
      };

      const paymentRequest = stripe.paymentRequest(event.detail.paymentRequestParams);
      const prButton = elements.create("paymentRequestButton", {
        paymentRequest,
      });

      (async () => {
        const result = await paymentRequest.canMakePayment();
        if (result) {
          document.getElementById("pc-payment-request-container").classList.add("show");
          prButton.mount("#pc-payment-request-button");
        }
      })();

      paymentRequest.on("paymentmethod", async (paymentMethodEvent) => {
        await completeCheckout(
          paymentMethodEvent.paymentMethod,
          paymentMethodEvent.complete,
        );
      });

      checkoutForm.addEventListener("submit", async (submitEvent) => {
        submitEvent.preventDefault();

        sendBrowserAgnosticEvent(checkoutForm, "payableCheckoutStarted");

        const selectedPaymentMethod = paymentMethodSelect && paymentMethodSelect.value;
        if (selectedPaymentMethod) {
          sendBrowserAgnosticEvent(checkoutForm, "savedPaymentMethodSubmit");

          return;
        }

        await completeCheckout();
      });
    }
  });
}
